<script setup>
import { useTemplatesStore } from '@/pages/Templates/stores/templates-store';
import { useHarbourStore } from '@/stores/harbour-store';
import debounce from '@/utils/debounce';
import { AgGridVue } from 'ag-grid-vue';
import Vue, { computed, getCurrentInstance, markRaw, ref, onActivated } from 'vue';
import { useCkeditorStore } from '../Ckeditor/stores/ckeditor-store';
import { useSidebarStore } from './stores/sidebar-store';
import { useTemplatesMenuStore } from './stores/templates-menu-store';

const templatesStore = useTemplatesStore();
const sidebarStore = useSidebarStore();
const harbourStore = useHarbourStore();
const templatesMenuStore = useTemplatesMenuStore();

const props = defineProps({
  customRowClick: {
    type: Function,
    default: null,
  },
});

const createTempTemplate = (group) => ({
  agreement_id: `AGREE-${Math.random().toString(36).slice(2)}_${Date.now()}`,
  title: '',
  template_group_id: group.groupId,
  is_template: true,
  ishidden: true,
});

const templatesData = computed(() => {
  const templates = [...templatesStore.myTemplates];
  const templatesGroups = templatesStore.templatesGroups || [];
  templatesGroups.forEach((group) => {
    const isTemplateFound = !!templates.find((t) => t.template_group_id === group.groupId);
    if (!isTemplateFound) {
      const tempTemplate = createTempTemplate(group);
      templates.push(tempTemplate);
    }
  });
  return templates;
});

const gridOptions = {
  ...harbourStore.defaultGridOptionsForGrids,
  rowBuffer: 4,
  groupDisplayType: 'groupRows',
  groupDefaultExpanded: 1,
  groupRowRendererParams: {
    getRowId: (params) => {
      return params.data.template_group_id;
    },
    innerRenderer: 'HrbrSidebarPaneTemplateGMenuRenderer',
    suppressCount: true,
    customRowClick: props.customRowClick
  },
  rowHeight: 36,
  rowClassRules: {
    'ag-row--active': (params) => {
      const { node } = params;
      return templatesStore.activeTemplateGroup === node.key;
    },
    'ag-row--hidden': (params) => !!params.data?.ishidden,
  },
};

const defaultColDef = {
  ...harbourStore.defaultColDefForGrids,
  getQuickFilterText: (params) => {
    const templateGroup = templatesStore.templatesGroups?.find(
      (templateGroup) => templateGroup.groupId === params.data.template_group_id,
    );
    const groupName = templateGroup?.groupName || '';
    return `${params.data.title} ${groupName}`;
  },
};

const columnDefs = [
  {
    field: 'allTemplates',
    rowGroup: true,
    hide: true,
    headerName: '',
    headerClass: 'hidden',
    cellClass: '',
    valueGetter: () => {
      return 'TEMPLGROUP-ALL-TEMPLATES';
    },
  },
  {
    field: 'groupId',
    rowGroup: true,
    hide: true,
    headerName: '',
    headerClass: 'hidden',
    maxWidth: 10,
    cellClass: '',
    valueGetter: (params) => {
      return params.data?.template_group_id;
    },
    sort: 'asc',
    comparator: (a, b, nodeA, nodeB) => {
      const templateA = templatesStore.templatesGroups?.find(
        (templateGroup) => templateGroup.groupId === a,
      )?.groupName;

      const templateB = templatesStore.templatesGroups?.find(
        (templateGroup) => templateGroup.groupId === b,
      )?.groupName;
      return templateA?.localeCompare(templateB);
    }
  },
  {
    field: 'title',
    cellClass: '',
    flex: 1,
    cellStyle: { paddingLeft: '69px', lineHeight: '1.1em' },
    cellRenderer: 'HrbrSidebarPaneTemplateRenderer',
    cellRendererParams: {
      customRowClick: props.customRowClick
    },
    sort: 'asc',
    comparator: (a, b, nodeA, nodeB) => {
      return a?.localeCompare(b);
    },
  },
];

const handleRowClick = (params) => {
  const isGroup = params.node.group;
  const target = params.event.target;
  const isActionsClicked = !!target.closest('[data-template-actions]');
  if (isActionsClicked) return;

  if (isGroup) {
    setCurrentGroup(params.node);
  } else {
    if (props.customRowClick) {
      props.customRowClick(params.data);
    } else {
      harbourStore.createLink(params.data);
    }
  }
};

const setCurrentGroup = (selectedNode) => {
  const allTemplatesGroup = 'TEMPLGROUP-ALL-TEMPLATES';

  let templateGroupId = null;
  if (selectedNode.key === allTemplatesGroup) {
    templateGroupId = allTemplatesGroup;
  } else {
    templateGroupId = selectedNode.allLeafChildren[0].data.template_group_id;
  }

  let activeGroupNode = getActiveGroupNode();

  const rowNodes = [selectedNode];
  if (activeGroupNode) rowNodes.push(activeGroupNode);

  templatesStore.setActiveTemplateGroup(templateGroupId);
  templatesMenuStore.agGridApi?.redrawRows({ rowNodes });
  sidebarStore.setSidebarOpen(false);
};

const getActiveGroupNode = () => {
  let activeGroupNode = null;
  templatesMenuStore.agGridApi?.forEachNode((node) => {
    if (node.key === templatesStore.activeTemplateGroup) activeGroupNode = node;
  });
  return activeGroupNode;
};

const scrollToActiveGroup = () => {
  let activeGroupNode = getActiveGroupNode();
  if (activeGroupNode) {
    templatesMenuStore.agGridApi?.ensureIndexVisible(activeGroupNode.rowIndex, 'top');
  }
};

const overlayNoRowsTemplate = `<div class="hrbr-sidebar-pane-template-groups-menu__no-template-groups">Loading templates...</div>`;

const onGridReady = (params) => {
  templatesMenuStore.setAgGridApi(markRaw(params.api));
  templatesMenuStore.setAgGridColumnApi(markRaw(params.columnApi));
  scrollToActiveGroup();
};

onActivated(() => {
  scrollToActiveGroup();
});

const onFilterChanged = (event) => {
  if (event.type !== 'filterChanged') return;
  expandNodesOnFiltering();
};

const onFilterChangedDebounced = debounce((event) => {
  onFilterChanged(event);
}, 300);

const expandNodesOnFiltering = () => {
  const filter = sidebarStore.filter?.toLowerCase().trim();
  if (!filter || filter.length < 2) {
    templatesMenuStore.agGridApi.collapseAll();
    templatesMenuStore.agGridApi.forEachNode((node) => {
      if (node.level === 0) {
        templatesMenuStore.agGridApi.setRowNodeExpanded(node, true);
      }
    });
    return;
  }
  const groupMatches = templatesStore.templatesGroups.filter((i) => {
    const name = i.groupName?.toLowerCase();
    return name?.includes(filter);
  });
  const groupIds = groupMatches.map((i) => i.groupId);

  const templateMatches = templatesStore.myTemplates.filter((i) => {
    const title = i.title.toLowerCase();
    return title.includes(filter);
  });
  const templateIds = templateMatches.map((i) => i.template_group_id);

  const allGroupIds = [...groupIds, ...templateIds];

  allGroupIds.forEach((id) => {
    expandNodesToTarget(id);
  });
};

const expandNodesToTarget = (templateId) => {
  const rowNode = templatesMenuStore.agGridApi.getRenderedNodes().find((i) => i.key == templateId);
  if (!rowNode) return;
  let tempNode = rowNode;
  while (tempNode.parent && tempNode.level !== -1) {
    tempNode.setExpanded(true);
    tempNode = tempNode.parent;
  }
};
</script>

<template>
  <div class="hrbr-sidebar-pane-template-groups-menu hrbr-grid-container">
    <div
      class="hrbr-sidebar-pane-template-groups-menu__tg-grid"
      data-testid="template-groups"
    >
      <ag-grid-vue
        class="hrbr-sidebar-pane-template-groups-menu__ag-grid ag-theme-alpine hrbr-ag-font-family"
        style="width: 100%; height: 100%"
        :gridOptions="gridOptions"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="templatesData"
        :headerHeight="38"
        :animateRows="false"
        ref="gridReference"
        @row-clicked="handleRowClick"
        @grid-ready="onGridReady"
        @filter-changed="onFilterChangedDebounced"
        :quickFilterText="sidebarStore.filter"
        :overlayNoRowsTemplate="overlayNoRowsTemplate" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-sidebar-pane-template-groups-menu {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  &__tg-grid {
    flex: 1;
    height: 100%;
  }

  &__ag-grid {
    height: 100%;
    --ag-grid-size: 3px;
    --ag-font-size: 14px;
    --ag-icon-size: 14px;
    --ag-row-height: 36px;
    --ag-row-hover-color: rgb(228 233 236 / 40%);
    --ag-data-color: #666f75;
    --ag-borders: none;
    --ag-header-foreground-color: #666f75;
    --ag-header-background-color: #e4e9ec;

    padding: 0;
    margin: 0;
  }

  :deep(&__no-template-groups) {
    font-size: 14px;
    color: #666f75;
    text-align: center;
    align-self: flex-start;
    width: 100%;
    margin-top: 20px;
  }

  :deep(.ag-header) {
    display: none;
  }

  :deep(.ag-group-value) {
    flex: 1;
  }

  :deep(.ag-row-odd) {
    background-color: transparent;
  }

  :deep(.ag-row) {
    cursor: pointer;
    border-bottom: 0;

    &--active {
      background: #E2E9FB;

      &.ag-row-hover::before {
        display: none;
      }
    }

    &--hidden {
      display: none;
    }
  }

  :deep(.ag-row-animation .ag-row) {
    transition: transform 0.2s, top 0.2s, background-color 0.1s, opacity 0.2s;
  }

  :deep(.ag-cell) {
    padding: 0 5px;
  }

  :deep(.hover-over) {
    background-color: #e4e9ec;
  }

  :deep(.ag-row-drag) {
    visibility: hidden;
  }

  :deep(.ag-icon.ag-icon-grip) {
    --ag-icon-font-family: 'Font Awesome 5 Pro';
    --ag-icon-font-code-grip: '\e411';
  }

  :deep(.ag-body-vertical-scroll) {
    display: none !important;
  }
}
</style>
